export const TESTNET_BINANCE_USDT_ADDRESS =
  "0x614BcD0853EFeE164Df71601B10904EA661C0fFD";
export const TESTNET_BINANCE_USDC_ADDRESS =
  "0x5A738Ced8A0e063C0e8d06Fce3D116fAF812F5b8";
export const TESTNET_BINANCE_WBNB_ADDRESS =
  "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
export const TESTNET_BINANCE_WBTC_ADDRESS = "";
export const TESTNET_BINANCE_WETH_ADDRESS = "";

export const postHeaders = {
  accept: "application/json",
  "content-type": "application/json",
  "x-api-key": process.env.REACT_APP_TATUM_X_API_KEY,
};

export const estimateGasUrl = "https://api.tatum.io/v3/blockchain/estimate";
export const sendBtcUrl = "https://api.tatum.io/v3/bitcoin/transaction";
export const getBalanceUrl = `https://api.tatum.io/v3/bitcoin/address/balance/`;
