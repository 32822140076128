import React from "react";
import { Link } from "react-router-dom";

const PayHeader = () => {
  return (
    <>
      <header className="pay-header">
        <div className="container">
          <div className="header-inner">
            <div className="logo">
              <Link to="/">
                <a>
                  <img src="images/logo.png" alt="logo" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default PayHeader;
