import axios from "axios";
import { estimateGasUrl, postHeaders, sendBtcUrl } from "./constant";

export async function getBTCWalletBalance(btcWalletAddress) {
  const url = `https://api.tatum.io/v3/bitcoin/address/balance/${btcWalletAddress}`;
  const headers = {
    accept: "application/json",
    "x-api-key": process.env.REACT_APP_TATUM_X_API_KEY,
  };

  const result = await axios
    .get(url, { headers })
    .then(async (response) => {
      return response.data.incoming - response.data.outgoing;
    })
    .catch((error) => {
      console.log(error.message);
      return 0;
    });

  return result;
}

export async function getBTCTxFee(fromAddress, amountInSatoshi) {
  try {
    const estimateGasPayload = {
      chain: "BTC",
      type: "TRANSFER",
      fromAddress: [fromAddress],
      to: [
        {
          address: fromAddress,
          value: Number(amountInSatoshi),
        },
      ],
    };

    console.log("getBTCTxFee postHeaders : ", postHeaders, estimateGasPayload);

    const gasResponse = await axios.post(estimateGasUrl, estimateGasPayload, {
      headers: postHeaders,
    });

    const output = gasResponse.data;

    return output.medium;
  } catch (err) {
    console.log("Error in Estimate Gas : ", err);
    return 0;
  }

  // const url = `https://api.tatum.io/v3/bitcoin/address/balance/${btcWalletAddress}`;
  // const headers = {
  //   accept: "application/json",
  //   "x-api-key": process.env.REACT_APP_TATUM_X_API_KEY,
  // };

  // const result = await axios
  //   .get(url, { headers })
  //   .then(async (response) => {
  //     return response.data.incoming - response.data.outgoing;
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //     return 0;
  //   });

  // return result;
}

export async function btcTransferFromAddress(
  walletPrivateKey,
  fromAddress,
  toAddress,
  fullAmount
) {
  console.log("Input data for btcTransferFromAddress : ", {
    walletPrivateKey,
    fromAddress,
    toAddress,
    fullAmount,
  });

  const fullAmountInSatoshi = Number(fullAmount).toFixed(8);

  console.log("MerchantAmount : ", fullAmountInSatoshi);

  let txGas = await getBTCTxFee(fromAddress, fullAmountInSatoshi);

  try {
    const amountAfterTax =
      Number(fullAmountInSatoshi) - Number(txGas["medium"]);

    console.log("amountAfterTax : ", Number(amountAfterTax.toFixed(8)));

    const sendBtcPayload = {
      fromAddress: [
        {
          address: fromAddress,
          privateKey: walletPrivateKey,
        },
      ],
      to: [
        {
          address: toAddress,
          value: Number(amountAfterTax.toFixed(8)), // 0.00001
        },
      ],
      fee: txGas["medium"],
      changeAddress: toAddress,
    };

    const sendBtcResponse = await axios.post(sendBtcUrl, sendBtcPayload, {
      headers: postHeaders,
    });

    console.log(" sendBtcResponse.data : ", sendBtcResponse.data);

    return sendBtcResponse.data;
  } catch (error) {
    console.log(
      "Error in Transaction Native token from paymentLink : ",
      error.response.data
    );
    return error.response.data;
  }
}
