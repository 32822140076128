import React, { useEffect, useState } from "react";
import PayHeader from "../../components/auth/PayHeader";
import { useParams } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode.react"; // Import the QR code library
import { copyToClipboard, formatDate } from "../../helper/helper";
import { getNetwork, getTronNetwork } from "../../helper/evmHelper";
import { IoCopyOutline } from "react-icons/io5";
import Swal from "sweetalert2";

const PaymentInformation = () => {
  const { paymentId } = useParams();
  const [paymentLink, setPaymentLink] = useState(null);
  const [timeLeft, setTimeLeft] = useState("");
  const [network, setNetwork] = useState(null);

  const fetchPaymentLink = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/get-tx-status`,
        {
          params: { paymentId },
        }
      );
      setPaymentLink(resp?.data?.data);
      if (resp?.data?.data?.chainId === "TRON") {
        const network = await getTronNetwork();
        setNetwork(network);
      } else {
        const network = await getNetwork(resp?.data?.data?.chainId);
        setNetwork(network);
      }
      console.log("network: ", network);

      if (resp?.data?.data?.status === "SUCCESS") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Payment Received",
          text: "Your payment has been received. Thank you!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error in transactions list", error);
    }
  };

  const calculateTimeLeft = () => {
    if (paymentLink?.expireTime) {
      const expireTime = new Date(paymentLink.expireTime * 1000);
      const currentTime = new Date();
      const difference = expireTime - currentTime;

      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeLeft("Expired");
      }
    }
  };

  useEffect(() => {
    fetchPaymentLink();
  }, []);

  useEffect(() => {
    if (paymentLink) {
      calculateTimeLeft();
      const timer = setInterval(() => {
        if (timeLeft !== "Expired") {
          calculateTimeLeft();
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [paymentLink, timeLeft]);

  return (
    <>
      <PayHeader />
      <div className="site-bg">
        <div className="inner-page-warper section-padding pay-bg">
          <div className="container">
            <div className="payment-information-bg">
              <div className="white-bg padd20">
                <h2>Payment Information</h2>
                <ul className="payment-information">
                  <li>
                    <div className="payment-left">Payment Id</div>
                    <div className="payment-right">{paymentId}</div>
                  </li>
                  <li>
                    <div className="payment-left">Crypto Code</div>
                    <div className="payment-right">{paymentLink?.code}</div>
                  </li>

                  <li>
                    <div className="payment-left">Total Amount to Send</div>
                    <div className="payment-right">
                      {paymentLink?.amount} {paymentLink?.symbol}
                    </div>
                  </li>

                  <li>
                    <div className="payment-left">Received So Far</div>
                    <div className="payment-right">
                      {paymentLink?.recivedAmount ?? 0} {paymentLink?.symbol}
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        flexDirection: "column",
                      }}
                    >
                      {timeLeft === "Expired" ? (
                        <div style={{ color: "red", marginTop: "20px" }}>
                          Payment Link Expired Now...!
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                            fontSize: "14px",
                          }}
                        >
                          {`Your Payment Id or QR Code for the Transaction`}
                        </div>
                      )}
                      {paymentLink?.status?.toUpperCase() ===
                        "PENDING FROM BUYER SIDE" && (
                        <div className="payment-QR">
                          <QRCode value={paymentLink?.toAddress || ""} />
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color:
                            paymentLink?.recivedAmount > 0 ? "#20cd50" : "red",
                          fontSize: "12px",
                        }}
                      >
                        {timeLeft.toLowerCase() === "expired"
                          ? `Received ${paymentLink?.recivedAmount ?? 0} ${
                              paymentLink?.symbol
                            } on ${network?.network} ${
                              network?.tokenType
                                ? "(" + network?.tokenType + ")"
                                : ""
                            } Network on this payment link.`
                          : `Transaction of ${paymentLink?.amount} ${
                              paymentLink?.symbol
                            } on ${network?.network} ${
                              network?.tokenType
                                ? "(" + network?.tokenType + ")"
                                : ""
                            } Network Only. Otherwise you may lose your crypto.`}
                      </div>
                    </div>
                  </li>

                  {paymentLink?.status.toUpperCase() ===
                    "PENDING FROM BUYER SIDE" && (
                    <>
                      <li>
                        <div className="payment-left">Send to Address</div>
                        <div className="payment-right">
                          {paymentLink?.toAddress}
                          <button
                            onClick={() =>
                              copyToClipboard(paymentLink?.toAddress)
                            }
                            style={{
                              marginLeft: "1rem",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <IoCopyOutline />
                          </button>
                        </div>
                      </li>

                      <li>
                        <div className="payment-left">Time left</div>
                        <div
                          className={`payment-right status ${
                            timeLeft.toLowerCase() !== "expired"
                              ? "success"
                              : "error"
                          }`}
                        >
                          {timeLeft}
                        </div>
                      </li>
                    </>
                  )}

                  <li>
                    <div className="payment-left">Buyer Email</div>
                    <div className="payment-right">
                      {paymentLink?.buyerEmail}
                    </div>
                  </li>
                  <li>
                    <div className="payment-left">Expiry Time</div>
                    <div className="payment-right">
                      {formatDate(paymentLink?.expireTime * 1000)}
                    </div>
                  </li>

                  <li>
                    <div className="payment-left">Created At</div>
                    <div className="payment-right">
                      {formatDate(paymentLink?.createdAt)}
                    </div>
                  </li>

                  <li>
                    <div className="payment-left">Payment Status</div>
                    <div
                      className={`status ${
                        paymentLink?.status.toUpperCase() === "SUCCESS"
                          ? "success"
                          : paymentLink?.status.toUpperCase() === "EXPIRED"
                          ? "error"
                          : "pending"
                      }`}
                    >
                      {paymentLink?.status}
                    </div>
                  </li>
                </ul>
                {/* <div className="pay-now-btn">
                  <a className="btn">Pay Now</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
