import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import OurServices from "./pages/OurServices";
import AboutUs from "./pages/AboutUs";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import HowItWorks from "./pages/HowItWorks";
import Pricing from "./pages/Pricing";
import Apis from "./pages/ApiDocs/Apis";
import ForgotPassword from "./pages/ForgotPassword";
import Inquiry from "./pages/Inquiry";
import TermsConditions from "./pages/TermsConditions";
import Faq from "./pages/Faq";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OurStory from "./pages/OurStory";
import ContactUs from "./pages/ContactUs";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Dashboard from "./pages/auth/Dashboard";
import Apps from "./pages/auth/Apps";
import ChangePassword from "./pages/auth/ChangePassword";
import CustomerSupport from "./pages/auth/CustomerSupport";
import ManageCryptoCurriencies from "./pages/auth/ManageCryptoCurriencies";
import MyProfile from "./pages/auth/MyProfile";
import Transactions from "./pages/auth/Transactions";
import DepositHistory from "./pages/auth/PaymentLinks";
import WithdrawlHistory from "./pages/auth/WalletTransactions";
import PrivateRoute from "./pages/private/PrivateRoute";
import HasToken from "./pages/private/HasToken";
import AppsDetail from "./pages/auth/AppsDetail";
import ConversionHistory from "./pages/auth/ConversionHistory";
import DepositReceive from "./pages/auth/DepositReceive";
import Pay from "./pages/auth/Pay";
import PaymentInformation from "./pages/auth/PaymentInformation";
import SendWithdraw from "./pages/auth/SendWithdraw";
import Settings from "./pages/auth/Settings";
import OTP from "./pages/OTPVerification";
import WithdrawInvoice from "./pages/auth/WithdrawInvoice";

import Notification from "./pages/auth/Notification";


// 
const App = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-otp" element={<OTP />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/apis" element={<Apis />} />
          <Route
            path="/payment-information/:paymentId"
            element={<PaymentInformation />}
          />

          <Route
            path="/reset-password"
            element={
              <HasToken>
                <ResetPassword />
              </HasToken>
            }
          />
          <Route
            path="/forget-password"
            element={
              <HasToken>
                <ForgotPassword />
              </HasToken>
            }
          />
          <Route
            path="/inquire-now"
            element={
              <HasToken>
                <Inquiry />
              </HasToken>
            }
          />

          {/* <Route path="/inquire-now" element={<Inquiry />} /> */}
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/contact-us" element={<ContactUs />} />

          {/* private routes */}

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/apps"
            element={
              <PrivateRoute>
                <Apps />
              </PrivateRoute>
            }
          />

          <Route
            path="/apps-detail/:appId"
            element={
              <PrivateRoute>
                <AppsDetail />
              </PrivateRoute>
              
            }
          />

          <Route
            path="/apps-payment-links/:appId"
            element={
              <PrivateRoute>
                <DepositHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/apps-wallet-transactions/:appId"
            element={
              <PrivateRoute>
                <WithdrawlHistory />
              </PrivateRoute>
            }
          />

          <Route
            path="/apps-conversion-history"
            element={
              <PrivateRoute>
                <ConversionHistory />
              </PrivateRoute>
            }
          />

          <Route
            path="/apps-deposit-receive/:appId"
            element={
              <PrivateRoute>
                <DepositReceive />
              </PrivateRoute>
            }
          />

          <Route
            path="/apps-send-withdraw/:appId"
            element={
              <PrivateRoute>
                <SendWithdraw />
              </PrivateRoute>
            }
          />

          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer-support"
            element={
              <PrivateRoute>
                <CustomerSupport />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard-manage-curriencies"
            element={
              <PrivateRoute>
                <ManageCryptoCurriencies />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-profile"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />

          <Route
            path="/withdraw-invoice"
            element={
              <PrivateRoute>
                <WithdrawInvoice />
              </PrivateRoute>
            }
          />

          <Route
            path="/pay"
            element={
              <PrivateRoute>
                <Pay />
              </PrivateRoute>
            }
          />

          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <Notification />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/payment-information/:paymentId"
            element={
              <PrivateRoute>
                <PaymentInformation />
              </PrivateRoute>
            }
          /> */}
        </Routes>
      </Router>
    </>
  );
};

export default App;
